<template>
  <RightSideBar
    v-if="isOpen"
    @submit="submitModeration"
    @close="isOpen = false"
    :loading="isLoading"
    submit="Save"
    cancel="Cancel"
    button-class="bg-dynamicBackBtn text-white"
    :disabled="isLoading"
    has-footer
  >
    <template v-slot:footer>
      <Button
        width="13rem"
        @click="submitModerationDraft"
        class="text-flame p-2 rounded-md"
        style="border: 1px solid #f15a29"
        :disabled="isLoading"
      >
        Save as Draft
      </Button>
    </template>
    <template v-slot:title>
      <p class="text-darkPurple font-bold text-lg leading-5 p-0">Moderate</p>
    </template>
    <template v-slot:subtitle>
      <Card
        class="flex justify-start items-center gap-2 p-2 mb-5"
        v-if="payload.length > 0"
      >
        <div>
          <img
            class="mr-3"
            :src="payload[0].photo"
            v-if="payload[0].photo"
            alt="user photo"
            style="height: 40px; width: 40px; border-radius: 5px"
          />
          <div
            style="height: 40px; width: 40px; border-radius: 5px"
            class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
            v-else
          >
            {{ $getInitials(`${payload[0].name}`) }}
          </div>
        </div>
        <div class="flex flex-col items-start font-semibold">
          <span
            class="text-optima capitalize font-semibold"
            style="font-size: 10px"
          >
            employee
          </span>
          <span class="text-darkPurple uppercase" style="font-size: 10px">
            {{ payload[0].name }}
          </span>
          <span class="text-romanSilver uppercase" style="font-size: 10px">
            {{ payload[0].designation }}
          </span>
        </div>
      </Card>
    </template>
    <template v-if="payload.length > 0">
      <div class="flex flex-col gap-5">
        <div class="flex flex-col gap-2 w-full">
          <p class="font-semibold text-darkPurple text-base">
            What score would you like to give
            <span class="text-blueCrayola">“{{ payload[0].name }}” ?</span>
          </p>
          <div class="flex justify-between items-center w-full">
            <div class="flex justify-start items-center gap-2 w-1/2">
              <p class="font-normal text-sm text-jet">Appraisal Score</p>
              <TextInput
                class="text-base text-darkPurple w-28 border-class"
                placeholder="Enter"
                :value="payload[0].appraisalScore"
                :disabled="true"
              />
            </div>
            <div class="flex justify-end items-center gap-2 w-1/2">
              <p class="font-normal text-sm text-jet">Team Average</p>
              <TextInput
                class="text-base text-darkPurple w-28 border-class"
                placeholder="Enter"
                :value="payload[0].averagePScore"
                :disabled="true"
              />
            </div>
          </div>
          <div class="flex justify-between items-center w-full">
            <div class="flex justify-start items-center gap-2 w-1/2">
              <p class="w-24 mr-1 font-normal text-sm text-jet">
                Organisation Average
              </p>
              <TextInput
                class="text-base text-darkPurple w-28 border-class"
                placeholder="Enter"
                :value="payload[0].orgScore"
                :disabled="true"
              />
            </div>
            <div class="flex justify-end items-center gap-2 w-1/2">
              <p class="w-24 mr-1 pl-3 font-normal text-sm text-jet">
                Functional Moderation
              </p>
              <TextInput
                class="text-base text-darkPurple w-28 border-class"
                placeholder="Enter"
                type="number"
                v-model="payload[0].moderatorScore"
                @input="moderationScore($event)"
              />
            </div>
          </div>
        </div>
        <div class="w-full mt-5">
          <Select
            label="Performance"
            placeholder="--Select--"
            :options="[
              'Poor Performer',
              'Below Average Performer',
              'Average Performer',
              'Top Performer',
              'Exceptional Performer',
            ]"
            @input="performance($event)"
            v-model="payload[0].performance"
          />
        </div>
        <div class="flex flex-col">
          <label class="font-semibold text-base text-darkPurple" for="comment">
            Add Comment/Remarks
          </label>
          <div class="textarea-border border border-romanSilver rounded-md p-1">
            <Textarea
              id="comment"
              :height="100"
              :maxlength="3"
              placeholder="Write your Comment/Remarks"
              @input="commentStructure($event)"
              v-model="payload[0].comments"
            />
            <div class="flex justify-end">
              <Icon size="s" icon-name="icons-send" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </RightSideBar>
</template>

<script>
import TextInput from "@scelloo/cloudenly-ui/src/components/text";
import Select from "@scelloo/cloudenly-ui/src/components/select";
import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import RightSideBar from "@/components/RightSideBar";

export default {
  components: {
    Card,
    Select,
    TextInput,
    Textarea,
    RightSideBar,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      payloadStructure: {
        employeeModerationId: "",
        orgId: "",
        moderatorId: "",
        moderationScore: "",
        performance: "",
        sequence: null,
        comment: "",
        status: "",
        moderationId: "",
      },
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },

    moderationScore(value) {
      this.payload[0].moderationScore = Number(value);
    },

    performance(value) {
      this.payload[0].performance = value;
    },

    commentStructure(val) {
      this.payload[0].comments = val;
    },

    submitModeration() {
      if (this.payload[0].id === "") {
        delete this.payloadStructure.moderationId;
      } else {
        this.payloadStructure.moderationId = this.payload[0].id;
      }

      this.payloadStructure.orgId = this.$orgId;
      this.payloadStructure.employeeModerationId =
        this.payload[0].employeeModerationId;
      this.payloadStructure.moderatorId = this.payload[0].moderatorUserId;
      this.payloadStructure.sequence = this.payload[0].sequenceNumber;
      this.payloadStructure.status = "completed";
      this.payloadStructure.moderationScore = this.payload[0].moderatorScore;
      this.payloadStructure.comment = this.payload[0].comments;
      this.payloadStructure.performance = this.payload[0].performance;

      this.$_postAddModerationScore(this.payloadStructure)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.$emit("close", true);
          this.isOpen = !this.isOpen;
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    submitModerationDraft() {
      if (this.payload[0].id === "") {
        delete this.payloadStructure.moderationId;
      } else {
        this.payloadStructure.moderationId = this.payload[0].id;
      }

      this.payloadStructure.orgId = this.$orgId;
      this.payloadStructure.employeeModerationId =
        this.payload[0].employeeModerationId;
      this.payloadStructure.moderatorId = this.payload[0].moderatorUserId;
      this.payloadStructure.sequence = this.payload[0].sequenceNumber;
      this.payloadStructure.status = "draft";
      this.payloadStructure.moderationScore = this.payload[0].moderatorScore;
      this.payloadStructure.comment = this.payload[0].comments;
      this.payloadStructure.performance = this.payload[0].performance;

      this.$_postAddModerationScore(this.payloadStructure)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.$emit("close", true);
          this.isOpen = !this.isOpen;
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },
  },
};
</script>

<style>
.rightSidebar {
  width: 520px !important;
}
.border-class input {
  border: 1px solid !important;
}
.textarea-border textarea {
  border: none !important;
}
</style>