<template>
  <RightSideBar
    v-if="isOpen"
    @submit="isOpen"
    @close="isOpen = false"
    :loading="isLoading"
    submit="Save"
    button-class="bg-dynamicBackBtn text-white"
    close-button
  >
    <template v-slot:title>
      <p class="text-darkPurple font-bold text-lg leading-5 p-0">
        Individual Moderation View
      </p>
    </template>
    <template v-slot:subtitle>
      <Card
        class="flex justify-start items-center gap-2 p-2 mb-5"
        v-if="payload.length > 0"
      >
        <div>
          <img
            class="mr-3"
            :src="payload[0].photo"
            v-if="payload[0].photo"
            alt="user photo"
            style="height: 40px; width: 40px; border-radius: 5px"
          />
          <div
            style="height: 40px; width: 40px; border-radius: 5px"
            class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
            v-else
          >
            {{ $getInitials(`${payload[0].name}`) }}
          </div>
        </div>
        <div class="flex flex-col items-start font-semibold">
          <span
            class="text-optima capitalize font-semibold"
            style="font-size: 10px"
          >
            employee
          </span>
          <span class="text-darkPurple uppercase" style="font-size: 10px">
            {{ payload[0].name }}
          </span>
          <span class="text-romanSilver uppercase" style="font-size: 10px">
            {{ payload[0].designation }}
          </span>
        </div>
      </Card>
    </template>
    <template v-if="payload.length > 0">
      <div class="flex flex-col gap-5">
        <div class="flex flex-col gap-2 w-full">
          <div class="flex justify-between items-center w-full">
            <div class="flex justify-start items-center gap-2 w-1/2">
              <p class="font-normal text-sm text-jet">Appraisal Score</p>
              <TextInput
                class="text-base text-darkPurple w-28 border-class"
                placeholder="Enter"
                :value="payload[0].appraisalScore"
                :disabled="true"
              />
            </div>
            <div class="flex justify-end items-center gap-2 w-1/2">
              <p class="font-normal text-sm text-jet">Team Average</p>
              <TextInput
                class="text-base text-darkPurple w-28 border-class"
                placeholder="Enter"
                :value="payload[0].averagePScore"
                :disabled="true"
              />
            </div>
          </div>
          <div class="flex justify-between items-center w-full">
            <div class="flex justify-start items-center gap-2 w-1/2">
              <p class="w-24 mr-1 font-normal text-sm text-jet">
                Organisation Average
              </p>
              <TextInput
                class="text-base text-darkPurple w-28 border-class"
                placeholder="Enter"
                :value="payload[0].orgScore"
                :disabled="true"
              />
            </div>
            <div class="flex justify-end items-center gap-2 w-1/2">
              <p class="w-24 mr-1 pl-3 font-normal text-sm text-jet">
                Functional Moderation
              </p>
              <TextInput
                class="text-base text-darkPurple w-28 border-class"
                placeholder="Enter"
                :value="payload[0].moderators.moderationScore"
                :disabled="true"
              />
            </div>
          </div>
        </div>
        <div class="w-full mt-5">
          <TextInput
            class="text-base text-darkPurple border-class"
            placeholder="Enter"
            label="Performance"
            :value="payload[0].moderators.performance"
            :disabled="true"
          />
        </div>
        <div class="flex flex-col">
          <label class="font-semibold text-base text-darkPurple" for="comment">
            Comment/Remarks
          </label>
          <Textarea
            id="comment"
            :disabled="true"
            :height="120"
            :maxlength="3"
            placeholder="Write your Comment/Remarks"
            :value="payload[0].moderators.comments"
          />
        </div>
        <Card class="flex justify-start items-center gap-2 p-2 mb-5">
          <div>
            <img
              class="mr-3"
              :src="payload[0].moderators.photo"
              v-if="payload[0].moderators.photo"
              alt="user photo"
              style="height: 40px; width: 40px; border-radius: 5px"
            />
            <div
              style="height: 40px; width: 40px; border-radius: 5px"
              class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
              v-else
            >
              {{ $getInitials(`${payload[0].moderators.name}`) }}
            </div>
          </div>
          <div class="flex flex-col items-start font-semibold">
            <span
              class="text-optima uppercase font-semibold"
              style="font-size: 10px"
            >
              Moderator
            </span>
            <span class="text-darkPurple uppercase" style="font-size: 10px">
              {{ payload[0].moderators.name }}
            </span>
            <span class="text-romanSilver uppercase" style="font-size: 10px">
              {{ payload[0].moderators.function }}
            </span>
          </div>
        </Card>
      </div>
    </template>
  </RightSideBar>
</template>

<script>
import TextInput from "@scelloo/cloudenly-ui/src/components/text";
import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import RightSideBar from "@/components/RightSideBar";

export default {
  components: {
    Card,
    TextInput,
    Textarea,
    RightSideBar,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style>
.rightSidebar {
  width: 520px !important;
}
.border-class input {
  border: 1px solid !important;
}
</style>