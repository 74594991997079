<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @submit="handleSubmit(submit)"
      @close="isOpen = false"
      button-class="bg-dynamicBackBtn text-white"
      >
      <template v-slot:title>
          <p class="text-darkPurple font-bold text-lg leading-5 p-0">
            Submit to Next Level
          </p>
      </template>
      <template v-slot:subtitle>
          <p class="text-darkPurple font-bold text-base pb-4 leading-5 p-0">
            Submit for next level moderation (CEO Moderation)
          </p>
      </template>
      <template>
        <div class="flex flex-col w-full gap-2">
          <div class="pb-4 leading-5 p-0">
            <p class="text-darkPurple font-bold text-base leading-5">
              Performance Scores
            </p>
          </div>
          <template>
            <div class="flex flex-col justify-start gap-2">
              <Card class="w-full py-2 px-4 flex justify-between items-end shadow rounded-md" noborder>
                <div class="flex justify-start items-start gap-0 w-1/2">
                  <Icon class-name="text-romanSilver self-start pt-1"
                    size="xs" icon-name="icon-manager"
                  />
                  <div class="flex flex-col justify-start items-start">
                    <p class="text-romanSilver text-xs leading-5 font-normal">
                      Line Manager
                    </p>
                    <p class="text-jet uppercase text-xs leading-5 font-semibold">
                      Jude Onah
                    </p>
                  </div>
                </div>
                <div class="flex justify-between items-center w-1/2">
                  <p class="text-jet uppercase text-xs leading-5 font-semibold">
                    Weight: 15%
                  </p>
                  <p class="text-jet uppercase text-xs leading-5 font-black">
                    3.10
                  </p>
                </div>
              </Card>
              <Card class="w-full py-2 px-4 flex justify-between items-end shadow rounded-md" noborder>
                <div class="flex justify-start items-start gap-0 w-1/2">
                  <Icon class-name="text-romanSilver self-start pt-1"
                    size="xs" icon-name="icon-manager"
                  />
                  <div class="flex flex-col justify-start items-start">
                    <p class="text-romanSilver text-xs leading-5 font-normal">
                     Moderator 1
                    </p>
                    <p class="text-jet uppercase text-xs leading-5 font-semibold">
                      Idris Mohamed
                    </p>
                  </div>
                </div>
                <div class="flex justify-between items-center w-1/2">
                  <p class="text-jet uppercase text-xs leading-5 font-semibold">
                    Weight: 25%
                  </p>
                  <p class="text-jet uppercase text-xs leading-5 font-black">
                    3.10
                  </p>
                </div>
              </Card>
              <Card class="w-full py-2 px-4 flex justify-between items-end shadow rounded-md" noborder>
                <div class="flex justify-start items-start gap-0 w-1/2">
                  <Icon class-name="text-romanSilver self-start pt-1"
                    size="xs" icon-name="icon-manager"
                  />
                  <div class="flex flex-col justify-start items-start">
                    <p class="text-romanSilver text-xs leading-5 font-normal">
                     Moderator 2
                    </p>
                    <p class="text-jet uppercase text-xs leading-5 font-semibold">
                      Nnamdi Johnson
                    </p>
                  </div>
                </div>
                <div class="flex justify-between items-center w-1/2">
                  <p class="text-jet uppercase text-xs leading-5 font-semibold">
                    Weight: 25%
                  </p>
                  <p class="text-jet uppercase text-xs leading-5 font-black">
                    3.20
                  </p>
                </div>
              </Card>
              <Card class="w-full py-2 px-4 flex justify-between items-end shadow rounded-md" noborder>
                <div class="flex justify-start items-start gap-0 w-1/2">
                  <Icon class-name="text-romanSilver self-start pt-1"
                    size="xs" icon-name="icon-manager"
                  />
                  <div class="flex flex-col justify-start items-start">
                    <p class="text-romanSilver text-xs leading-5 font-normal">
                     Chief Moderator (You)
                    </p>
                    <p class="text-jet uppercase text-xs leading-5 font-semibold">
                      Titi Jegede
                    </p>
                  </div>
                </div>
                <div class="flex justify-between items-center w-1/2">
                  <p class="text-jet uppercase text-xs leading-5 font-semibold">
                    Weight: 45%
                  </p>
                  <p class="text-jet uppercase text-xs leading-5 font-black">
                    3.30
                  </p>
                </div>
              </Card>
            </div>
          </template>
          <Card class="w-full rounded-md" norborder>
            <div class="py-2 px-3 flex justify-between items-center w-full bg-cultured">
              <span class="font-semibold text-xs leading-5 text-jet">
                Weighted Average
              </span>
              <span class="font-bold text-lg leading-5 text-flame">
                3.02
              </span>
            </div>
          </Card>
        </div>
      </template>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import Card from '@scelloo/cloudenly-ui/src/components/card';
  import Icon from "@/components/Icon";
  import RightSideBar from "@/components/RightSideBar";

  export default {
    components: {
      ValidationObserver,
      Icon,
      RightSideBar,
      Card,
    },
    data() {
      return {
        isOpen: false,
      }
    },
    methods: {
      toggle() {
        this.isOpen = !this.isOpen;
      },
    }
  };
</script>

<style>
  .rightSidebar{
    width: 520px !important;
  }
</style>
