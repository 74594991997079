<template>
  <div class="w-full pt-5">
    <template>
      <div class="flex items-center mx-2">
        <div class="border-r border-romanSilver h-6 flex">
          <BackButton
            label="Back"
            @onClick="$router.back()"
            variant="secondary"
          />
        </div>
        <h1 class="text-xl text-left font-extrabold mx-4">
          Moderation Details
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>
    <template v-if="enabledModeration">
    <template v-if="isLoading">
      <div class="h-full mt-20" v-if="isLoading">
        <Loader size="xxs" :loader-image="false" />
      </div>
    </template>
    <template v-else>
      <template v-if="startAgreement || endAgreement">
        <template>
          <Card class="p-5 flex flex-col m-5 gap-4">
            <template>
              <div class="flex justify-between items-center">
                <span
                  class="font-bold text-lg leading-5 tracking-normal capitalize text-darkPurple"
                >
                  {{ agreementData.title }}
                </span>
              </div>
            </template>
            <template>
              <div
                class="border border-dashed bg-white border-romanSilver rounded-md gap-4 p-3 mt-4"
              >
                <div
                  class="flex justify-between items-center w-full gap-4 pb-2 cursor-pointer"
                  @click="showDetails = !showDetails"
                >
                  <span
                    class="font-black text-xs leading-5 uppercase tracking-wider text-romanSilver"
                  >
                    Performance Details
                  </span>
                  <span
                    class="w-9/12 border-t border-solid border-romanSilver h-0"
                  />
                  <Icon
                    :icon-name="showDetails ? 'chevronDown' : 'chevronRight'"
                    class-name="text-darkPurple mx-auto self-center"
                    size="xms"
                  />
                </div>
                <div
                  class="flex flex-col gap-2"
                  :class="{ hidden: !showDetails }"
                >
                  <div class="flex p-3 shadow rounded-md gap-2">
                    <Icon
                      size="s"
                      icon-name="doc-cycle"
                      class-name="text-romanSilver self-start pt-1"
                    />
                    <div class="flex flex-col">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-darkPurple uppercase"
                      >
                        {{ agreementData.cycle }}
                      </span>
                      <span
                        class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                      >
                        {{ agreementData.description }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div
                      class="flex p-3 shadow rounded-md gap-2 w-2/5 mr-2 mt-2 flex-grow"
                    >
                      <Icon
                        size="xs"
                        icon-name="calendar"
                        class-name="text-romanSilver self-start pt-1"
                      />
                      <div class="flex flex-col">
                        <span
                          class="font-black leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                        >
                          Period
                        </span>
                        <span
                          class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                        >
                          {{
                            $DATEFORMAT(
                              new Date(agreementData.period.start),
                              "MMMM dd, yyyy"
                            )
                          }}
                          -
                          {{
                            $DATEFORMAT(
                              new Date(agreementData.period.end),
                              "MMMM dd, yyyy"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="flex p-3 shadow rounded-md gap-2 w-2/5 mt-2 flex-grow"
                      v-for="(cycle, index) in agreementCycles"
                      :key="index"
                    >
                      <Icon
                        class-name="text-romanSilver self-start pt-1"
                        size="xs"
                        icon-name="reviewicon"
                      />
                      <div class="flex flex-col">
                        <span
                          class="font-black leading-5 text-xs tracking-wide text-blueCrayola uppercase"
                        >
                          {{ cycle.name }}
                        </span>
                        <span
                          class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                        >
                          {{
                            $DATEFORMAT(new Date(cycle.start), "MMMM dd, yyyy")
                          }}
                          -
                          {{
                            $DATEFORMAT(new Date(cycle.end), "MMMM dd, yyyy")
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <!----<div class="flex justify-evenly items-center gap-2 w-full">
                <div class="flex flex-col p-3 shadow rounded-md w-full">
                  <span
                    class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                  >
                    Total Employees
                  </span>
                  <span
                    class="font-semibold leading-5 text-xs tracking-wide text-darkPurple uppercase"
                  >
                    123
                  </span>
                </div>
                <div class="flex flex-col p-3 shadow rounded-md w-full">
                  <span
                    class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                  >
                    Number of Submitted
                  </span>
                  <span
                    class="font-semibold leading-5 text-xs tracking-wide text-mediumSeaGreen uppercase"
                  >
                    2
                  </span>
                </div>
                <div class="flex flex-col p-3 shadow rounded-md w-full">
                  <span
                    class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                  >
                    Pending
                  </span>
                  <span
                    class="font-semibold leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                  >
                    121
                  </span>
                </div>
                <div class="flex flex-col p-3 shadow rounded-md w-full">
                  <span
                    class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                  >
                    Compliance
                  </span>
                  <span
                    class="font-semibold leading-5 text-xs tracking-wide text-desire uppercase"
                  >
                    1.6%
                  </span>
                </div>
              </div>-->
                </div>
              </div>
            </template>
            <template>
              <div class="flex justify-between items-center gap-10">
                <div class="w=3/5 flex justify-start gap-2">
                  <div
                    class="border border-romanSilver rounded-md p-2 flex justify-between items-center gap-2"
                  >
                    <Icon
                      class-name="text-romanSilver self-start pt-0"
                      size="xs"
                      icon-name="toolbox"
                    />
                    <div class="flex flex-col gap-0">
                      <span
                        class="font-black text-xs leading-5 uppercase text-darkPurple"
                      >
                        Organization Score
                      </span>
                      <span class="font-semibold text-sm leading-5 uppercase">
                        {{ orgScore }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="border border-romanSilver rounded-md p-2 flex justify-between items-center gap-2"
                  >
                    <Icon
                      class-name="text-romanSilver self-start pt-0"
                      size="xs"
                      icon-name="toolbox"
                    />
                    <div class="flex flex-col gap-0">
                      <span
                        class="font-black text-xs leading-5 uppercase text-darkPurple"
                      >
                        employee pre-moderation average
                      </span>
                      <span class="font-semibold text-sm leading-5 uppercase">
                        {{ averagePreScore }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="border border-romanSilver rounded-md p-2 flex justify-between items-center gap-2"
                  >
                    <Icon
                      class-name="text-romanSilver self-start pt-0"
                      size="xs"
                      icon-name="toolbox"
                    />
                    <div class="flex flex-col gap-0">
                      <span
                        class="font-black text-xs leading-5 uppercase text-darkPurple"
                      >
                        employee post-moderation average
                      </span>
                      <span class="font-semibold text-sm leading-5 uppercase">
                        ---
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </template>
        <template>
          <div class="w-full px-5 my-5">
            <Card class="p-5 pt-0">
              <CardFooter reloadcard showcalendar />
            </Card>
          </div>
        </template>
        <template>
          <div class="px-5 h-full">
            <Table
              :headers="headers"
              :items="itemsData"
              :has-number="false"
              :loading="isLoading"
              style="width: 100%"
            >
              <template v-slot:item="{ item }">
                <div v-if="item.appraisee" class="flex">
                  <div class="flex">
                    <img
                      class="mr-3"
                      :src="item.data.photo"
                      v-if="item.data.photo"
                      alt="user photo"
                      style="height: 30px; width: 30px; border-radius: 5px"
                    />
                    <div
                      style="height: 30px; width: 30px; border-radius: 5px"
                      class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                      v-else
                    >
                      {{ $getInitials(`${item.data.appraisee}`) }}
                    </div>
                  </div>
                  <div
                    class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
                  >
                    <div class="flex">
                      <span class="text-darkPurple capitalize mr-3">
                        {{ item.data.appraisee }}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  v-if="item.functions"
                  class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
                >
                  <span class="text-darkPurple capitalize">{{
                    item.data.functions
                  }}</span>
                  <span class="text-optima text-xs uppercase">{{
                    item.data.designation
                  }}</span>
                </div>
                <div v-if="item.appraisalScore" class="flex justify-start">
                  <span
                    class="text-center text-sm leading-6 font-normal text-darkPurple"
                  >
                    {{ item.data.appraisalScore }}
                  </span>
                </div>
                <div v-if="item.lineManager" class="flex justify-start">
                  <div class="flex justify-between items-start gap-2">
                    <div
                      class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
                    >
                      <div class="flex">
                        <span class="text-darkPurple capitalize">
                          {{ item.data.lineManager.details.fname }}
                          {{ item.data.lineManager.details.lname }}
                        </span>
                        <span
                          class="text-blueCrayola ml-1 font-black uppercase"
                          style="font-size: 10px"
                        >
                          {{
                            getIdentity(item.data.lineManager.details.userId)
                          }}
                        </span>
                      </div>
                      <div
                        class="text-carrotOrange"
                        v-if="
                          item.data.lineManager.moderate.status === 'pending'
                        "
                      >
                        <span class="uppercase text-10px">Not Completed</span>
                      </div>
                      <div
                        class="text-mediumSeaGreen"
                        v-if="
                          item.data.lineManager.moderate.status === 'completed'
                        "
                      >
                        <span class="uppercase text-10px">Completed</span>
                      </div>
                      <div
                        class="text-romanSilver"
                        v-if="
                          item.data.lineManager.moderate.status ===
                          'not_completed'
                        "
                      >
                        <span class="uppercase text-10px">In Draft</span>
                      </div>
                    </div>
                    <div
                      class="pt-1"
                      v-if="
                        item.data.lineManager.moderate.status === 'completed'
                      "
                      @click="
                        toggleIndividualViewModerationView(
                          item.data.id,
                          item.data.lineManager.details.userId
                        )
                      "
                    >
                      <Icon
                        class-name="text-flame cursor-pointer"
                        icon-name="icon-message-flame"
                      />
                    </div>
                  </div>
                </div>
                <div v-for="header in moderatorHeaders" :key="header.value">
                  <div
                    v-if="item[header.value]"
                    class="flex justify-between items-start gap-2"
                  >
                    <div
                      class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
                    >
                      <div class="flex">
                        <span class="text-darkPurple capitalize">
                          {{ item.data[header.value].details.fname }}
                          {{ item.data[header.value].details.lname }}
                        </span>
                        <span
                          class="text-blueCrayola ml-1 font-black uppercase"
                          style="font-size: 10px"
                        >
                          {{
                            getIdentity(item.data[header.value].details.userId)
                          }}
                        </span>
                      </div>
                      <div
                        class="text-carrotOrange"
                        v-if="
                          item.data[header.value].moderate.status === 'pending'
                        "
                      >
                        <span class="uppercase text-10px">Not Completed</span>
                      </div>
                      <div
                        class="text-mediumSeaGreen"
                        v-if="
                          item.data[header.value].moderate.status ===
                          'completed'
                        "
                      >
                        <span class="uppercase text-10px">Completed</span>
                      </div>
                      <div
                        class="text-romanSilver"
                        v-if="
                          item.data[header.value].moderate.status ===
                          'not_completed'
                        "
                      >
                        <span class="uppercase text-10px">In Draft</span>
                      </div>
                    </div>
                    <div
                      class="pt-1 -ml-4"
                      v-if="
                        item.data[header.value].moderate.status === 'completed'
                      "
                      @click="
                        toggleIndividualViewModerationView(
                          item.data.id,
                          item.data[header.value].details.userId
                        )
                      "
                    >
                      <Icon
                        class-name="text-flame cursor-pointer"
                        icon-name="icon-message-flame"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="item.modScore" class="flex w-full">
                  <span
                    class="text-center w-full text-sm leading-6 font-normal text-darkPurple"
                  >
                    {{ item.data.modScore }}
                  </span>
                </div>
                <div v-if="item.id" class="cursor-pointer">
                  <Menu left @click.stop="" class="">
                    <template v-slot:title>
                      <Icon icon-name="more_icon" size="xs" />
                    </template>
                    <template>
                      <div
                        class="flex flex-col w-38 h-18 p-2 justify-start items-start"
                      >
                        <div
                          class="hover:bg-ghostWhite w-full rounded-md"
                          @click="toggleViewOrgModeration(item.data.id)"
                        >
                          <div
                            class="flex justify-start items-center p-2 gap-2"
                          >
                            <Icon
                              icon-name="icon-eye"
                              class-name="text-blueCrayola"
                              size="xs"
                            />
                            <span
                              class="text-darkPurple text-sm leading-5 font-normal"
                            >
                              View
                            </span>
                          </div>
                        </div>
                        <div
                          class="hover:bg-ghostWhite w-full rounded-md"
                          v-if="checkModerationView(item.data.index)"
                          @click="
                            toggleModerateModeration(item.data.lineManager)
                          "
                        >
                          <div
                            class="flex justify-start items-center p-2 gap-2"
                          >
                            <Icon
                              icon-name="maximize"
                              class-name="text-carrotOrange"
                              size="xs"
                            />
                            <span
                              class="text-darkPurple text-sm leading-5 font-normal"
                            >
                              Moderate
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </Menu>
                </div>
              </template>
            </Table>
            <SubmitModeration ref="submitModeration" />
            <OrgModerationView
              ref="orgModerationView"
              :payload="employeeModeration"
              :is-loading="modalLoading"
            />
            <OrgModerateModeration
              ref="orgModerate"
              :payload="moderationPayload"
              :is-loading="modalLoading"
              @close="reloadData($event)"
            />
            <IndividualModerationView
              ref="individualView"
              :payload="individualModeration"
              :is-loading="modalLoading"
            />
          </div>
        </template>
      </template>

      <template v-else>
        <div
          class="flex flex-col justify-center items-center gap-5 px-10 pb-20 w-full"
        >
          <Icon size="" class-name="w-80 h-80" icon-name="illustration" />
          <p class="font-normal text-base leading-5 text-jet text-center w-3/6">
            Moderation period starts on
            {{ $DATEFORMAT(new Date(agreementOpenDate), "MMMM dd, yyyy") }}
            and ends on
            {{ $DATEFORMAT(new Date(agreementCloseDate), "MMMM dd, yyyy") }}
          </p>
        </div>
      </template>
    </template>
    </template>

      <template v-else>
        <div class="w-full flex flex-col justify-center items-center mt-20">
          <icon icon-name="empty_agreement" size="l" style="width: 300px" />
          <div class="w-1/2 text-base text-center">
            No Performance Moderation set up for this Template.
          </div>
        </div>
      </template>

  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Menu from "@scelloo/cloudenly-ui/src/components/Menu";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Icon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import timeFormatMixin from "@/utilities/timeFormatMixin";
import paramsMixin from "@/utilities/paramsMixin";
import SubmitModeration from "./Modals/SubmitModeration";
import OrgModerationView from "./Modals/OrgModerationView";
import OrgModerateModeration from "./Modals/OrgModerateModeration";
import IndividualModerationView from "./Modals/IndividualModerationView";

export default {
  name: "ESSOrganisationModeration",
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    Table,
    Breadcrumb,
    BackButton,
    Icon,
    Menu,
    Card,
    CardFooter,
    SubmitModeration,
    OrgModerationView,
    OrgModerateModeration,
    IndividualModerationView,
  },
  data() {
    return {
      isLoading: true,
      showDetails: false,
      closeCommentModal: false,
      enabledModeration: true,
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Moderation",
          href: "EssPerformanceModeration",
          id: "EssPerformanceModeration",
        },
        {
          disabled: false,
          text: "Moderation Details",
          href: "EssPerformanceModerationDetails",
          id: "EssPerformanceModerationDetails",
        },
      ],
      moderatorHeaders: [],
      headers: [
        { title: "Appraisee", value: "appraisee" },
        { title: "Function", value: "functions" },
        { title: "appraisal score", value: "appraisalScore" },
      ],
      modalLoading: true,
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
      employeeModeration: [],
      individualModeration: [],
      agreementData: {
        title: null,
        cycle: null,
        description: null,
        period: { start: null, end: null },
      },
      agreementCycles: [],
      orgScore: 0,
      averagePreScore: 0,
      averagePostScore: 0,
      moderationArr: [],
      employeeModerationArr: [],
      currentModerators: [],
      itemsData: [],
      currentSequenceNumber: 0,
      moderationPayload: [],
      startAgreement: false,
      endAgreement: false,
      agreementOpenDate: "",
      agreementCloseDate: "",
    };
  },
  methods: {
    toggleSubmitModeration() {
      this.$refs.submitModeration.toggle();
    },

    toggleIndividualViewModerationView(userId, moderatorId) {
      this.modalLoading = true;
      this.individualModeration = [];

      this.$_getEmployeeModeration(userId).then((response) => {
        const payloadData = response.data.data;

        payloadData.moderators.moderators.forEach((moderator) => {
          if (moderator.userId === moderatorId) {
            this.individualModeration.push({
              id: payloadData.id,
              averageModerationScore: payloadData.averageModerationScore,
              appraisalScore: payloadData.moderation.appraisalScore,
              userId,
              name: `${payloadData.fname} ${payloadData.lname}`,
              photo: payloadData.photo,
              function: payloadData.function,
              designation: payloadData.designation,
              moderators: {
                moderationScore: moderator.moderatorScore,
                name: `${moderator.fname} ${moderator.lname}`,
                comments: moderator.comment,
                photo: moderator.photo,
                function: "---",
                performance: moderator.performance,
              },
              orgScore: this.orgScore,
              averagePScore: this.averagePreScore,
            });
          }
        });
        this.modalLoading = false;
      });
      this.$refs.individualView.toggle();
    },

    toggleViewOrgModeration(userId) {
      this.modalLoading = true;
      this.employeeModeration = [];

      this.$_getEmployeeModeration(userId).then((response) => {
        const payloadData = response.data.data;

        this.employeeModeration.push({
          id: payloadData.id,
          averageModerationScore: payloadData.averageModerationScore,
          appraisalScore: payloadData.moderation.appraisalScore,
          userId,
          name: `${payloadData.fname} ${payloadData.lname}`,
          photo: payloadData.photo,
          function: payloadData.function,
          designation: payloadData.designation,
          moderators: payloadData.moderators,
          orgScore: this.orgScore,
          averagePScore: this.averagePreScore,
        });

        this.modalLoading = false;
      });

      this.$refs.orgModerationView.toggle();
    },

    toggleModerateModeration(moderationData) {
      this.modalLoading = true;
      this.moderationPayload = [];
      let moderationId = "";
      let performance = "";
      let score = null;
      let comments = "";
      let status = "";
      this.$refs.orgModerate.toggle();

      this.$_singleModeration(
        moderationData.moderate.performanceModerationId
      ).then((response) => {
        const payloadData = response.data.data;

        const moderators = payloadData.moderation.moderation_scores;

        moderators.forEach((moderator) => {
          if (moderator.moderatorId === this.$AuthUser.id) {
            moderationId = moderator.id;
            performance = moderator.performance;
            score = moderator.moderatorScore;
            comments = moderator.comment;
            status = moderator.status;
          }
        });

        this.moderationPayload.push({
          id: moderationId,
          employeeModerationId: moderationData.moderate.performanceModerationId,
          averageModerationScore: payloadData.averageModerationScore,
          appraisalScore: payloadData.moderation.appraisalScore,
          name: `${payloadData.fname} ${payloadData.lname}`,
          photo: payloadData.photo,
          function: payloadData.function,
          designation: payloadData.designation,
          orgScore: this.orgScore,
          averagePScore: this.averagePreScore,
          moderatorScore: Number(score),
          comments,
          status,
          moderatorUserId: this.$AuthUser.id,
          sequenceNumber: this.currentSequenceNumber,
          performance,
        });

        this.modalLoading = false;
      });
    },

    toggleAddModerationComment() {
      this.$refs.viewModeration.toggle();
    },

    getIdentity(userId) {
      let isUser = "";

      if (userId === this.$AuthUser.id) {
        isUser = "(You)";
      }

      return isUser;
    },

    checkModerationView(index) {
      let isState = false;

      const userIndex = this.currentModerators.indexOf(this.$AuthUser.id, 0);

      if (userIndex === index) {
        isState = true;
      }

      return isState;
    },

    getAppraisalCycles() {
      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;
        this.agreementData.description = appraisal[0].description;
        if (appraisal[0].cycles.length > 0) {
          appraisal[0].cycles.map((cycle) => {
            this.agreementCycles.push({
              name: cycle.name,
              start: this.setDates(cycle.appraisal_starts).toISOString(),
              end: this.setDates(cycle.appraisal_ends).toISOString(),
            });
            return {};
          });
        }
      });
    },

    getAgreement() {
      this.$_getOrgPerformanceAgreement().then((response) => {
        const result = response.data.data;
        result.forEach((info) => {
          if (info.agreementDetail.year === Number(this.$route.params.year)) {
            this.agreementData.title =
              info.agreementDetail.goal_performance_templates.agreement_title;
            this.agreementData.cycle =
              info.agreementDetail.goal_performance_templates.agreement_title;
            this.agreementData.description =
              info.agreementDetail.goal_performance_templates.agreement_description;
            this.agreementData.period.start = this.setDates(
              info.agreementDetail.goal_performance_templates.appraisal_cycle
                .start
            ).toISOString();
            this.agreementData.period.end = this.setDates(
              info.agreementDetail.goal_performance_templates.appraisal_cycle
                .end
            ).toISOString();
            this.loading = false;
          }
        });
      });
    },

    getAllModerationScore(userId) {
      this.$_getEmployeeModeration(userId).then((response) => {
        this.itemsData.forEach((arrData) => {
          if (arrData.id === userId) {
            // eslint-disable-next-line no-param-reassign
            arrData.modScore = response.data.data.averageModerationScore;
          }
        });
      });

      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },

    getModerationData() {
      const moderationArr = [];

      this.$_getStartModerators(
        this.$AuthUser.id,
        this.$route.params.year
      ).then((response) => {
        this.orgScore = response.data.orgAppraisalScore;
        this.averagePreScore = response.data.averageEmployeeScore;

        const moderationData = response.data.moderations;

        moderationData.map((data) => {
          moderationArr.push(data.moderators);
          this.currentModerators.push(data.currentModerator);
          return {};
        });

        for (let i = 0; i < moderationArr.length; i++) {
          moderationArr[i].map((arrData) => {
            if (!this.employeeModerationArr.includes(arrData.employee.userId)) {
              this.moderationArr.push({
                appraisee: `${arrData.employee.fname} ${arrData.employee.lname}`,
                id: arrData.employee.userId,
                designation: arrData.employee.designation,
                functions: arrData.employee.function,
                photo: arrData.employee.photo,
                appraisalScore:
                  arrData.moderate.sequenceNumber === 0
                    ? arrData.moderate.moderatorScore
                    : "",
                index: i,
              });
            }
            this.employeeModerationArr.push(arrData.employee.userId);
            return {};
          });

          this.setModerators(moderationArr[i]);
        }

        this.setModeratorsHeader(moderationArr);
      });
    },

    setModeratorsHeader(moderationArr) {
      let moderatorKey = "";
      let moderatorVal = "";

      const moderatorLength = moderationArr[0].length;

      moderationArr[0].map((arrData, index) => {
        if (index === 0) {
          moderatorKey = "Line Manager";
          moderatorVal = "lineManager";
        } else if (index > 0 && index < moderatorLength - 1) {
          moderatorKey = `Moderator${index}`;
          moderatorVal = `moderator${index}`;
        } else if (index === moderatorLength - 1) {
          moderatorKey = "Chief Moderator";
          moderatorVal = "chiefModerator";
        }

        this.headers.push({
          title: `${moderatorKey}`,
          value: `${moderatorVal}`,
        });

        if (index > 0) {
          this.moderatorHeaders.push({
            value: `${moderatorVal}`,
          });
        }

        return {};
      });

      this.headers.push(
        { title: "Mod. Score", value: "modScore" },
        { title: "", value: "id", image: true }
      );
    },

    setModerators(moderationArr) {
      const moderators = [];
      let moderatorKey = "";

      const moderatorLength = moderationArr.length;

      moderationArr.map((arrData, index) => {
        if (index === 0) {
          moderatorKey = "lineManager";
        } else if (index > 0 && index < moderatorLength - 1) {
          moderatorKey = `moderator${index}`;
        } else if (index === moderatorLength - 1) {
          moderatorKey = "chiefModerator";
        }

        if (this.employeeModerationArr.includes(arrData.employee.userId)) {
          if (arrData.moderator.userId === this.$AuthUser.id) {
            this.currentSequenceNumber = arrData.moderate.sequenceNumber;
          }

          moderators.push({
            [moderatorKey]: {
              details: arrData.moderator,
              moderate: arrData.moderate,
            },
          });
        }

        return {};
      });

      moderators.push(
        this.moderationArr.reduce((r, i) => Object.assign(r, i), {})
      );

      this.itemsData.push(moderators.reduce((r, c) => Object.assign(r, c), {}));

      this.itemsData.forEach((data) => {
        this.getAllModerationScore(data.id);
      });
    },

    reloadData(val) {
      this.headers = [];

      if (val) {
        this.isLoading = true;
        this.itemsData = [];
        this.moderationArr = [];
        this.employeeModerationArr = [];
        this.currentSequenceNumber = "";
        this.moderatorHeaders = [];
        this.employeeModerationArr = [];
        this.currentModerators = [];

        this.headers.push(
          { title: "Appraisee", value: "appraisee" },
          { title: "Function", value: "functions" },
          { title: "appraisal score", value: "appraisalScore" }
        );

        this.getModerationData();
      }
    },

    getTemplate() {
      this.$_getOneTemplate(this.$route.params.goalId).then((response) => {
        const template = response.data.PerformanceTemplate;

        if(template.enable_moderation) {
          this.enabledModeration = true;
        const agreementStartDate = this.setDates(template.functional_moderation_start);
        const agreementEndDate = this.setDates(template.functional_moderation_end);

        this.agreementOpenDate = agreementStartDate.toISOString();
        this.agreementCloseDate = agreementEndDate.toISOString();

        const today = new Date();

        if (today >= agreementStartDate && today <= agreementEndDate) {
          this.startAgreement = true;
          this.endAgreement = false;
          this.getAgreement();
          this.getAppraisalCycles();
          this.getModerationData();
        }
        if (today > agreementStartDate && today > agreementEndDate) {
          this.startAgreement = false;
          this.endAgreement = true;
          this.getAgreement();
          this.getAppraisalCycles();
          this.getModerationData();
        }
        if (today < agreementStartDate && today < agreementEndDate) {
          this.startAgreement = false;
          this.endAgreement = false;
        }
        } else {
          this.isLoading = false;
          this.enabledModeration = false;
        }
      });
    },
  },

  mounted() {
    this.getTemplate();
  },
};
</script>

<style scoped>
.button-class {
  padding: 10px 40px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.text-10px {
  font-size: 10px;
}
</style>